import { axiosInstanceWithUserAuth, axiosInstance } from "./axios-instance";

export async function startCall(checkoutId: string) {
  try {
    const response = await axiosInstance.post(
      `/engine/shopify/start-shopify-call`,
      {
        checkoutId,
      }
    );

    const data: any = await response.data;
    if (data.success) {
      return "Appel lancé avec succès!";
    } else {
      return "Erreur lors du lancement de l'appel.";
    }
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function syncCheckouts() {
  try {
    const response = await axiosInstanceWithUserAuth.get(`/sync-checkouts`);

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function getCheckoutsByAccountId(accountId: string) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/checkouts`, {
      accountId,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function syncOrders() {
  try {
    const response = await axiosInstanceWithUserAuth.get(`/sync-orders`);

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function getOrdersByAccountId(accountId: string) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/orders`, {
      accountId,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function getDashboardDatas(accountId?: string) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/datas/dashboard`, {
      accountId,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function getAccount(accountUuid: string) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/accounts/my`, {
      accountUuid,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function addAccount(payload: any) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/accounts`, {
      ...payload,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function setAccount(payload: any) {
  try {
    const response = await axiosInstanceWithUserAuth.put(`/accounts`, {
      ...payload,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function updateAccount(accountUuid: string, payload: any) {
  try {
    const response = await axiosInstanceWithUserAuth.put(`/accounts/my`, {
      accountUuid,
      ...payload,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}

export async function getAccounts() {
  try {
    const response = await axiosInstanceWithUserAuth.get(`/accounts`);

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}
export async function completeCall(callSid: string) {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/engine/stop-call`, {
      callSid,
    });

    const data: any = await response.data;

    return data;
  } catch (error) {
    console.error("Erreur:", error);
  }
}
