import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Menu.css';
import { useAuth } from '../AuthContext/AuthContext';
import { getAccounts } from '../../libs/api'

function Menu() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [accounts, setAccounts] = useState<{ uuid: string; shop_name: string }[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const location = useLocation();
  const { userProfile, setIsAuthenticated } = useAuth();

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMobileMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    window.location.href = '/auth';
  };

  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const accountId = e.target.value;
    setSelectedAccount(accountId);
    localStorage.setItem('selectedAccount', accountId); // Sauvegarde dans le localStorage
    window.location.reload(); // Recharge pour appliquer les changements
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      const data = await getAccounts(); // Remplacez par votre fonction pour récupérer les comptes
      setAccounts(data);

      const savedAccount = localStorage.getItem('selectedAccount');
      if (savedAccount) {
        setSelectedAccount(savedAccount);
      } else if (data.length > 0) {
        setSelectedAccount(data[0].uuid); // Sélectionne le premier compte par défaut
      }
    };

    fetchAccounts();
  }, []);

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isExpanded ? 'expanded' : 'hidden'}`}>
      <h1>{isCollapsed ? <span className="tel">M</span> : <span>Marian<span className="tel">.tel</span></span>}</h1>
      
      <div className="account-selector">
        <label htmlFor="account-select" className={isCollapsed ? 'collapsed-label' : ''}>
          {isCollapsed ? '' : 'Compte:'}
        </label>
        <select
          id="account-select"
          value={selectedAccount || ''}
          onChange={handleAccountChange}
        >
          {accounts.map((account: any) => (
            <option key={account.uuid} value={account.uuid}>
              {account.shop_name}
            </option>
          ))}
        </select>
      </div>

      <ul>
        <li>
          <NavLink to="/dashboard" className={location.pathname === '/dashboard' ? 'active' : ''}>
            <span className="icon">💰</span>
            <span className="text">Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/checkouts" className={location.pathname === '/checkouts' ? 'active' : ''}>
            <span className="icon">🛒</span>
            <span className="text">Checkouts / Calls</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders" className={location.pathname === '/orders' ? 'active' : ''}>
            <span className="icon">📦</span>
            <span className="text">Orders</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/account" className={location.pathname === '/account' ? 'active' : ''}>
            <span className="icon">🏠</span>
            <span className="text">Shop connected</span>
          </NavLink>
        </li>
      </ul>
      {userProfile?.is_admin && (
        <ul>
          <li><hr /></li>
          <li>
            <NavLink to="/accounts" className={location.pathname === '/accounts' ? 'active' : ''}>
              <span className="icon">📄</span>
              <span className="text">Accounts</span>
            </NavLink>
          </li>
        </ul>
      )}
      <div className="toggle-btn" onClick={toggleMenu}>
        {isCollapsed ? '➕' : '➖'}
      </div>
      <div className="hamburger-btn" onClick={toggleMobileMenu}>
        ☰
      </div>
      <div className="logout-section">
        <a href="#logout" className="logout-link" onClick={handleLogout}>
          Se déconnecter
        </a>
      </div>
    </div>
  );
}

export default Menu;
