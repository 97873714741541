import { useEffect, useState } from 'react';
import './Auth.css';
import { auth } from '../libs/auth-users';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext/AuthContext';

function Auth(): JSX.Element {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await auth(email, password, isLogin ? 'login' : 'signup');

      if (response.token !== undefined) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("refresh_token", response.refresh_token);

        setMessage('Login successful! Redirecting...');

        setIsAuthenticated(true);
        window.location.href = '/checkouts';
      } else {
        setMessage(response.data.message);
      }
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h1>Connectez-vous à votre compte</h1>
        <p>
          ou <span onClick={() => setIsLogin(!isLogin)} className="auth-link">{isLogin ? 'créer un compte' : 'se connecter'}</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Adresse e-mail</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Entrer votre adresse e-mail"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <div className="password-wrapper">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Entrer votre mot de passe"
              />
            </div>
          </div>
          {message && <div className="error-message">{message}</div>}
          <button type="submit" className="auth-btn">{loading ? 'Chargement...' : isLogin ? 'Connexion' : 'Créer un compte'}</button>
        </form>
      </div>
    </div>
  );
}

export default Auth;
