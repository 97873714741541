// components/Table/Table.tsx
import React from 'react';
import './Table.css';

type TableHeader<T> = {
  key: keyof T | string;  // Ajout de `string` pour les clés personnalisées
  label: string;
  width?: string;
  render?: (value: any, row: T) => React.ReactNode;
};

type TableProps<T> = {
  headers: TableHeader<T>[];
  data: T[];
};

const Table = <T extends { [key: string]: any }>({ headers, data }: TableProps<T>): JSX.Element => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={String(header.key)} style={{ width: header.width || 'auto' }}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td key={String(header.key)}>
                  {header.render ? header.render(row[header.key], row) : row[header.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
