import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Checkouts.css';
import { completeCall, getCheckoutsByAccountId, syncCheckouts } from '../libs/api';
import Menu from '../components/Menu/Menu';
import { startCall } from '../libs/api';
import { Checkout } from '../types/Checkout';
import { useAuth } from '../components/AuthContext/AuthContext';
import Table from '../components/Table/Table';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function CheckoutsPage() {
  const [checkouts, setCheckouts] = useState<Checkout[]>([]);
  const [filteredCheckouts, setFilteredCheckouts] = useState<Checkout[]>([]);
  const [selectedAccount] = useState<string | null>(localStorage.getItem('selectedAccount'));
  const [filter, setFilter] = useState<string>('call_not_started'); // Par défaut, on affiche `call_not_started`
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuth();

  useEffect(() => {
    if (selectedAccount) {
      fetchCheckoutsForAccount(selectedAccount);
    }
  }, [selectedAccount]);

  const fetchCheckoutsForAccount = async (accountId: string) => {
    const data = await getCheckoutsByAccountId(accountId);
    setCheckouts(data);
  };

  useEffect(() => {
    const subscription = supabase
      .channel('marian_checkouts')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_checkouts' }, payload => {
        console.log('Changement détecté:', payload);
        if (selectedAccount) {
          fetchCheckoutsForAccount(selectedAccount);
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [selectedAccount]);

  useEffect(() => {
    const filtered = checkouts.filter(checkout => checkout.call_status === filter);
    setFilteredCheckouts(filtered);
  }, [checkouts, filter]);

  const handleCall = async (checkoutUuid: string) => {
    try {
      await startCall(checkoutUuid);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de l\'appel.');
    }
  };

  const handleCompleteCall = async (callSid: string) => {
    try {
      await completeCall(callSid);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la clôture de l\'appel.');
    }
  };

  const sync = async () => {
    try {
      setLoading(true);
      await syncCheckouts();
      setLoading(false);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la synchro.');
    }
  };

  const headers = [
    { key: 'customer_name', label: 'Client', render: (_: any, row: Checkout) =>
      <div>
        {row.customer_name}
        <br />
        {row.customer_phone} ({row.customer_country})
      </div>
    },
    { key: 'checkout_created_at', label: 'Date du panier' },
    { key: 'total_price', label: 'Montant du panier', width: '15%' },
    { key: 'call_status', label: 'Status de l\'appel', width: '15%' },
    {
      key: 'call_date',
      label: 'Date de l\'appel',
      render: (_: any, row: Checkout) =>
        row.call_status !== 'call_not_started' ? (
          <div>début: {row.call_started_at}
            <br />
            fin: {row.call_finished_at}
          </div>
        ) : null,
    },
    {
      key: 'actions',
      label: 'Action',
      render: (_: any, row: Checkout) =>
        row.call_status === 'call_not_started' ? (
          <button onClick={() => handleCall(row.uuid)}>Call</button>
        ) : row.call_status === 'call_in_progress' ? (
          <button onClick={() => handleCompleteCall(row.twilio_call_sid)}>Stop</button>
        ) : null,
    },
    {
      key: 'mp3',
      label: 'MP3',
      render: (_: any, row: Checkout) =>
        row.call_status === 'call_finished' && row.twilio_call_recording_url ? (
          <audio controls src={row.twilio_call_recording_url}></audio>
        ) : null,
    },
  ];

  const total = filteredCheckouts.reduce((acc, checkout) => acc + parseFloat(checkout.total_price), 0);

  return (
    <div className="container">
      <Menu />
      <div className="main-content">
        <div className="content-datas">
          {userProfile?.is_admin && (
            <>
              <button onClick={sync}>Sync {loading && 'loading...'}</button>
            </>
          )}
          <div className="filter-container">
            <label htmlFor="status-filter">Filtrer par statut :</label>
            <select
              id="status-filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="call_not_started">Non commencé</option>
              <option value="call_in_progress">En cours</option>
              <option value="call_finished">Terminé</option>
            </select>
          </div>
          <Table headers={headers} data={filteredCheckouts} />
        </div>
        <div className="summary">
          <span>Total des montants de paniers: {total.toFixed(2)}</span>
          <br />
          <span>Potentiel pour Marian: {((total * 15) / 100).toFixed(2)}€</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutsPage;
