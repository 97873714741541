import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Accounts.css';
import { getAccounts, addAccount, setAccount } from '../libs/api'
import Menu from '../components/Menu/Menu';
import Table from '../components/Table/Table';
import { Account } from '../types/Account';

// Initialisation de Supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function AccountsPage(): JSX.Element {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState<Account | Partial<Account>>({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      const data: any = await getAccounts();
      setAccounts(data);
    };

    fetchAccounts();

    // Mise en place de la souscription pour les mises à jour en temps réel
    const subscription = supabase
      .channel('marian_accounts')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_accounts' }, payload => {
        console.log('Changement détecté:', payload);
        fetchAccounts(); // Recharge les comptes en cas de changement
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  const handleAddOrUpdate = async () => {
    if (isEditing) {
      await setAccount(formData);
      setMessage('Compte mis à jour avec succès.');
    } else {
      await addAccount(formData);
      setMessage('Compte ajouté avec succès.');
    }

    setFormData({});
    setShowForm(false);
    setIsEditing(false);
  };

  const handleEdit = (account: Account) => {
    setFormData(account);
    setIsEditing(true);
    setShowForm(true);
  };

  const handleAdd = () => {
    setFormData({});
    setIsEditing(false);
    setShowForm(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const headers = [
    { key: 'shop_name', label: 'Boutique' },
    { key: 'shopify_domain', label: 'Domain' },
    { key: 'shop_currency', label: 'Devise' },
    { key: 'app_installed_at', label: 'Date d\'installation' },
    { key: 'app_uninstalled_at', label: 'Date de désinstallation' },
    { key: 'shop_owner_name', label: 'Propriétaire', render: (_: any, row: Account) => (<div>{row.shop_owner} ({row.shop_owner_email})</div>) },
    { key: 'shopify_discount_name', label: 'Discount', render: (_: any, row: Account) => (<div>{row.shopify_discount_name} ({row.shopify_discount_value})</div>) },
    { key: 'voice_tone_description', label: 'Voix', render: (_: any, row: Account) => (<div>{row.voice_tone_description} ({row.voice_name})</div>) },
    {
      key: 'actions',
      label: 'Actions',
      render: (_: any, row: Account) => (
        <button onClick={() => handleEdit(row)} className="action-btn edit-btn">
          Modifier
        </button>
      )
    }
  ];

  return (
    <div className="container">
      <Menu />
      <div className="main-content">
        <div className="header-actions">
          <h2>Gestion des comptes</h2>
          <button onClick={handleAdd} className="add-btn">Ajouter un compte</button>
          {message}
        </div>
        <Table headers={headers} data={accounts} />
        {showForm && (
          <div className="account-form">
            <h3>{isEditing ? 'Modifier un compte' : 'Ajouter un compte'}</h3>
            <form onSubmit={(e) => { e.preventDefault(); handleAddOrUpdate(); }}>
              <div className="form-group">
                <label>Boutique:</label>
                <input type="text" name="shop_name" value={formData.shop_name || ''} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Domain:</label>
                <input type="text" name="shopify_domain" value={formData.shopify_domain || ''} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Devise:</label>
                <input type="text" name="shop_currency" value={formData.shop_currency || ''} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Propriétaire:</label>
                <input type="text" name="shop_owner" value={formData.shop_owner || ''} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Email du propriétaire:</label>
                <input type="email" name="shop_owner_email" value={formData.shop_owner_email || ''} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Nom de discount:</label>
                <input type="text" name="shopify_discount_name" value={formData.shopify_discount_name || ''} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Valeur de discount:</label>
                <input type="text" name="shopify_discount_value" value={formData.shopify_discount_value || ''} onChange={handleInputChange} />
              </div>
              <div className="form-actions">
                <button type="submit" className="save-btn">{isEditing ? 'Mettre à jour' : 'Ajouter'}</button>
                <button type="button" className="cancel-btn" onClick={() => setShowForm(false)}>Annuler</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountsPage;
