import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Accounts.css';
import { getAccount, updateAccount } from '../libs/api';
import Menu from '../components/Menu/Menu';
import { Account } from '../types/Account';
import Table from '../components/Table/Table';

// Initialisation de Supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function AccountPage(): JSX.Element {
  const [selectedAccount] = useState<string | null>(localStorage.getItem('selectedAccount'));
  const [account, setAccount] = useState<Account | null>(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    helloSentence: '',
    voiceName: '',
    voiceToneDescription: '',
    description: '',
    discountCodeName: '',
    discountCodeValue: ''
  });

  useEffect(() => {
    const fetchAccount = async (selectedAccount: string) => {
      const data: any = await getAccount(selectedAccount);
      console.log(data)
      setAccount(data);
      setFormData({
        helloSentence: data.hello_sentence || '',
        voiceName: data.voice_name || '',
        voiceToneDescription: data.voice_tone_description || '',
        description: data.shop_prompt_description || '',
        discountCodeName: data.shopify_discount_name || '',
        discountCodeValue: data.shopify_discount_value || ''
      });
    };

    if (selectedAccount) { fetchAccount(selectedAccount); }

    const subscription = supabase
      .channel('marian_accounts')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_accounts' }, payload => {
        console.log('Changement détecté:', payload);
        if (selectedAccount) { fetchAccount(selectedAccount); }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [selectedAccount]);

  const headers = [
    { key: 'shop_name', label: 'Boutique' },
    { key: 'shopify_domain', label: 'Domain' },
    { key: 'shop_currency', label: 'Devise' },
    { key: 'app_installed_at', label: 'Date d\'installation' },
    { key: 'shop_owner_name', label: 'Propriétaire', render: (_: any, row: Account) => (<div>{row.shop_owner} ({row.shop_owner_email})</div>) }
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('')
    setLoading(true)

    if (account) {
      const updatedData = {
        hello_sentence: formData.helloSentence,
        voice_name: formData.voiceToneDescription,
        shop_prompt_description: formData.description,
        shopify_discount_name: formData.discountCodeName,
        shopify_discount_value: formData.discountCodeValue
      };
      await updateAccount(account.uuid, updatedData);
      setMessage('Updated with success')
    }
    setLoading(false)
  };

  return (
    <div className="container">
      <Menu />
      <div className="main-content">
        <Table headers={headers} data={account ? [account] : []} />
        
        <h2 className="form-title">Modifier les informations de l'account</h2>
        <form onSubmit={handleSubmit} className="account-form">
          <div className="form-group">
            <label>Phrase d'accroche:</label>
            <input
              type="text"
              name="helloSentence"
              value={formData.helloSentence}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Voix de Marian:</label>
            <input
              type="text"
              name="voiceName"
              value={formData.voiceName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Description du ton de la voix de Marian:</label>
            <input
              type="text"
              name="voiceToneDescription"
              value={formData.voiceToneDescription}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Description du shop:</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Discount Code Name:</label>
            <input
              type="text"
              name="discountCodeName"
              value={formData.discountCodeName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Discount Code Value:</label>
            <input
              type="text"
              name="discountCodeValue"
              value={formData.discountCodeValue}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="submit-btn">Enregistrer les modifications</button>
          {loading ? '...' : message}
        </form>
      </div>
    </div>
  );
}

export default AccountPage;
